// packages block
import { FC, Fragment, useContext, useEffect } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Nav, NavItem, Navbar } from 'reactstrap';
import { Link, useLocation, withRouter } from 'react-router-dom';
// constants, svgs and graphql block
import { AppContext } from '../../../context';
import { SidebarPropType } from '../../../interfaces';
import LogoImage from '../../../assets/images/logo.svg';
import { FETCH_MY_AGREEMENTS } from '../../../Pages/agreements/gql';
import {
  DASHBOARD_ROUTE,
  NAV_LINKS,
  SIGN_OUT,
  SWITCH_THEME,
} from '../../../utils/constant';
import { UPDATE_USER_THEME, FETCH_CURRENT_USER } from '../../../Pages/settings/gql';

import './style.scss';
import { IComingState } from '../../../Pages/agreements/interfaces';

const Sidebar: FC<SidebarPropType> = ({
  isOpen,
  isMenuActive,
  setIsMenuActive,
}): JSX.Element => {
  const { pathname } = useLocation<IComingState>()
  const [fetchMyAgreements] = useLazyQuery(FETCH_MY_AGREEMENTS);
  const [userTheme] = useMutation(UPDATE_USER_THEME);
  const [currentUserDetail, { data }] = useLazyQuery(FETCH_CURRENT_USER);
  const { user, setContextRoles, setContextUser, theme, setTheme } =
    useContext(AppContext);
  const onClickThemeChanger = async (theme: string) => {
    let responseForChangeUserTheme = await userTheme({
      variables: {
        userThemeInput: {
          userId: user?.id,
          theme: theme
        }
      }
    });
    if (responseForChangeUserTheme?.data?.userTheme?.status === 200) {
      currentUserDetail()
    }
  }
  useEffect(() => {
    if (user?.userSelectedTheme) {
      if (user?.userSelectedTheme === 'dark-mode') {
        setTheme('dark');
        document.body.classList.add('dark-mode');
      } else {
        setTheme('light');
        document.body.classList.remove('dark-mode');
      }
    }
  }, [setTheme, user?.userSelectedTheme, user, theme, data]);

  useEffect(() => {
    fetchMyAgreements();
  }, [fetchMyAgreements]);

  return (
    <div className={isOpen ? 'sidebar open' : 'sidebar'}>
      <div className="sidebar-logo">
        <Link to={DASHBOARD_ROUTE}>
          <img src={LogoImage} alt="logo" />
        </Link>
      </div>

      <Navbar>
        <Nav className="navbar-menu" navbar>
          {NAV_LINKS.filter((item) => item?.title !== window.localStorage.getItem("sideBarItem")).map((item, index) => {
            return (
              <Fragment key={index}>
                {item.title === 'Impersonate' ? (
                  user?.canImpersonate ? (
                    <div>
                      <NavItem
                        key={item.title}
                        onClick={() => {
                          if (isMenuActive) {
                            setIsMenuActive(false);
                          }
                        }}
                      >
                        <Link to={item.url}>
                          <div className="nav-icon">{item.icon}</div>
                          <span>{item.title}</span>
                        </Link>
                      </NavItem>
                    </div>
                  ) : (
                    ''
                  )
                ) : (
                  <>
                    <NavItem
                      key={item.title}
                      onClick={() => {
                        if (isMenuActive) {
                          setIsMenuActive(true);
                        }
                      }}
                    >
                      <div className="d-flex">
                        <Link
                          to={item.url}
                          className={pathname === item.url ? "is-active" : ''}
                        >
                          <div className="nav-icon ">{item.icon}</div>
                          {item?.title}
                        </Link>
                      </div>
                    </NavItem>
                  </>
                )}
              </Fragment>
            );
          })}
        </Nav>

        <div>
          <Nav className="navbar-bottom">
            <NavItem className="theme-switch mb-3">
              <div className="nav-icon">
                <SWITCH_THEME />
              </div>
              Theme
              <label className="switch ml-3">
                <input
                  type="checkbox"
                  checked={theme === 'dark'}
                  onClick={() => {
                    if (theme === 'dark') {
                      setTheme('light');
                      onClickThemeChanger('light-mode')
                      document.body.classList.remove('dark-mode');
                    } else {
                      setTheme('dark');
                      onClickThemeChanger('dark-mode')
                      document.body.classList.add('dark-mode');
                    }
                  }}
                />
                <span className="slider"></span>
              </label>
            </NavItem>
            <NavItem
              onClick={() => {
                if (isMenuActive) {
                  setIsMenuActive(false);
                }
              }}
            >
              <a
                style={{ fontWeight: 500 }}
                onClick={() => {
                  document.body.classList.remove('light-mode');
                  document.body.classList.remove('dark-mode');
                  window.localStorage.removeItem('token');
                  window.localStorage.removeItem('previousToken');
                  setContextRoles([]);
                  setContextUser(null);
                }}
                href="/"
              >
                <div className="nav-icon">
                  <SIGN_OUT />
                </div>
                Signout
              </a>
            </NavItem>
          </Nav>
          <div className="version-text">Version 1.67.7</div>
        </div>
      </Navbar>
    </div>
  );
};

export default withRouter(Sidebar);
